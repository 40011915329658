import React from 'react';
import { BrowserRouter, Navigate, Route, Routes as Switch } from 'react-router-dom';

import './assets/tailwindcss/tailwind.css';

import { permissions } from './permissionsList';

import withAuthorization from './common/HOC/withAuthorization';

import Header from './loggedinComponents/Header/Header';
import Sidebar from './loggedinComponents/Sidebar/Sidebar';
import NotFound from './common/NotFound/NotFound';
import ContentBox from './common/Box/ContentBox';
import SearchResults from 'loggedinComponents/Header/SearchResults';
import Impersonate from 'loggedinComponents/Impersonate/Impersonate';

const PatientsRouter = React.lazy(() => import('./loggedinComponents/Patients/PatientsRouter'));
const HomepageRouter = React.lazy(() => import('./loggedinComponents/Homepage/HomepageRouter'));

const AppRoutes = () => {
    const AuthorizedHomepageRouter = withAuthorization(HomepageRouter, permissions.homepage);
    const AuthorizedPatientsRouter = withAuthorization(PatientsRouter, permissions.patients);
    const AuthorizedImpersonate = withAuthorization(Impersonate, permissions.impersonate);

    return (
        <React.Suspense fallback={<div>Loading....</div>}>
            <ContentBox>
                <Switch>
                    <Route path='/homepage/*' element={<AuthorizedHomepageRouter />} />
                    <Route path='/patients/*' element={<AuthorizedPatientsRouter />} />
                    <Route path = '/results' element = {<SearchResults/>}/>
                    <Route path = '/impersonate' element = {<AuthorizedImpersonate/>}/>
                    <Route path='/not-found' element={<NotFound />} />
                    <Route path='*' element={<Navigate to="/homepage" replace />} />
                </Switch>
            </ContentBox>
        </React.Suspense>
    );
}

const AuthorisedRoutes = () => {
	return (
		<BrowserRouter>
            <React.Suspense fallback={<div>Loading....</div>}>
                {/* /Header */}
                <Header />

                <div className='flex'>
                    {/* Sidebar */}
                    <Sidebar />
                    {/* Routes */}
                    <AppRoutes />
                </div>
            </React.Suspense>
        </BrowserRouter>
	);
}

export default AuthorisedRoutes;
