import React, { useState } from 'react';
import { Card } from 'antd';
import { debounce, get } from 'lodash';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { axiosGet } from 'utils/axiosAsync/async';
import { setUserData } from 'utils/appUtils';

import ClinicusButton from 'common/Button/Button';
import ScioComboBox from 'common/ClinicusGrid/ScioComboBox';
import Error from 'common/Error/Error';

const Impersonate = () => {
    const [selectedEmail, setSelectedEmail] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [loading, setLoading] = useState("idle");
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const { user } = useSelector(store => store.userSlice);

    const onChange = (name, selectedOps) => {
        setSelectedEmail(selectedOps);
    }

    const getSuggestedEmails = debounce(async (searchedVal) => {
        setLoading("loading");
        const criterias = [{
            selectedColumn: "email",
            selectedOperator: "contains",
            selectedValue: searchedVal
        }];

        const bodyPayload = {
            page: 1,
            limit: 20,
            sort: JSON.stringify({ email: "asc" }),
            criterias: encodeURIComponent(JSON.stringify(criterias))
        };

        const [err, resp] = await axiosGet("/object/get/list/contacts", bodyPayload);

        if(err) {
            setError("Error fetching emails...");
            setLoading("idle");
            return;
        }
        setError("");
        const reqEmails = get(resp, 'data.data', []);

        const newOps = reqEmails.map(({ email }) => ({ label: email, value: email }));

        setEmailOptions(newOps);
        setLoading("idle");
    }, 500);

    const handleImpersonate = async (event) => {
        event.stopPropagation();
        const [err, resp] = await setUserData(selectedEmail[0].value, true, user.email);

        if(err) {
            setError("Cannot impersonate now. Please try later.");
        } else {
            navigate("/homepage");
            setError("");
        }
    }

    return (
        <Card className="w-full p-4 h-screen" styles={{ body: { padding: 0 } }}>
            <div className="flex flex-col xm:flex-row gap-3 w-full">
                <div className="w-full space-y-3">
                    <ScioComboBox
                        field={{ name: "impersonatedEmail", options: emailOptions }}
                        value={selectedEmail}
                        onChange={onChange}
                        onChangeSearchTerm={getSuggestedEmails}
                        error={error}
                        loading={loading === "loading"}
                        isSingleSelect={true}
                    />
                    <Error error={error} />
                </div>

                <ClinicusButton type="primary" className="max-h-10" disabled={!(selectedEmail && selectedEmail.length)} onClick={handleImpersonate}>
                    Impersonate
                </ClinicusButton>
            </div>
        </Card>
    );
}

export default Impersonate;
