import React, { useState } from "react";
import { Input, Space } from "antd";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const SearchComponent = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (value) => {
    if(!value)  return;
    // Navigate to the results page with state
    const url = `/results?search_query=${encodeURIComponent(value)}`;
    navigate(url);
    setSearchValue("");
  };

  return (
    <Space direction="vertical" className="w-full">
      <Search
        placeholder="Search for patient"
        allowClear
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onSearch={onSearch}
        className="w-96"
      />
    </Space>
  );
};

export default SearchComponent;
