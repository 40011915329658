import { get } from 'lodash';

const operatorsMap = {
	"NUMBER": [{
		label: 'Equals', value: 'equals'
	}, {
		label: "Not Equals", value: "notEquals"
	}, {
		label: 'Greater Than', value: 'greaterThan'
	}, {
		label: 'Greater Than Equals To', value: 'greaterThanEqualsTo'
	}, {
		label: 'Less Than', value: 'lessThan'
	}, {
		label: 'Less Than Equals To', value: 'lessThanEqualsTo'
	}],
	"STRING": [{
		label: 'Is', value: 'equals'
	}, {
		label: "Is Not", value: "notEquals"
	}, {
		label: 'Contains', value: 'contains'
	}, {
		label: 'Does Not Contains', value: 'notContains'
	}, {
		label: 'In', value: 'in'
	}, {
		label: 'Starts With', value: 'startsWith'
	}, {
		label: 'Ends With', value: 'endsWith'
	}, {
		label: 'Exists', value: 'exists'
	}, {
		label: 'Not Exists', value: 'notExists'
	}],
	"BOOLEAN": [{
		label: 'Is', value: 'equals'
	}, {
		label: "Is Not", value: "notEquals"
	}],
	"DATE": [{
		label: 'After', value: 'after'
	}, {
		label: 'Before', value: 'before'
	}, {
		label: 'Date Between', value: 'dateBetween'
	}]
};

const addOperators = (columns) => {
    const modifiedColumns = columns.map(column => {
		let operators = [];
		let columnType = get(column, 'type', 'text');
		let bFilter = columnType.toUpperCase();

		//Todo: Column type need to be passed for date columns
		const isTimestampColumn = ["createdAt", "updatedAt"].indexOf(column.dataField) >= 0;
		if (isTimestampColumn) {
			bFilter = "DATE";
			column.type = "date";
		}

		if (bFilter === "NUMBER") {
			operators = operatorsMap["NUMBER"];
		} else if (bFilter === "TEXT" || bFilter === "STRING") {
			operators = operatorsMap["STRING"];
		} else if (bFilter === "BOOLEAN" || bFilter === "SELECT") {
			operators = operatorsMap["BOOLEAN"];
		} else if (bFilter === "DATE") {
			operators = operatorsMap["DATE"];
		}

		return {
			dataField: column.dataField,
			label: column.text,
			operators: operators,
			text: column.text,
			type: column.type || 'text',
			value: column.dataField
		};
    });

    return modifiedColumns
}

export {
	addOperators,
	operatorsMap
};
