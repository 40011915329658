import React, { Component, Fragment } from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
import { chunk, isEmpty, isEqual, isObject, isArray, get, map, remove, pick, startCase } from 'lodash'
import { Row, Col, Label, Button, FormGroup, FormFeedback } from 'reactstrap'
import DatePicker from "react-datepicker";
import { Radio } from 'antd'
import "react-datepicker/dist/react-datepicker.css";
import "./FormBuilder.scss";

import FieldGroup from './FieldGroup';
import ClinicusComboBox from '../ClinicusGrid/ClinicusComboBox';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import { getDefaultStartDateFilter, getDefaultEndDateFilter } from "../Utils/dateUtils";
import ClinicusDivider from 'common/ClinicusDivider';

const { Group: RadioGroup } = Radio;
class FormBuilder extends Component {
    static propTypes = {
        fields: Proptypes.array.isRequired,
        cols: Proptypes.number
    }

    static defaultProps = {
        fields: [],
        cols: 1
    }


    constructor(props) {
        super(props)
        this.state = {
            formValues: {},
            validationErrors: {},
            fields: []
        }
    }

    handleFieldChanges = (e, passedOnChange) => {
        let { name, value, type } = e.target
        value = (type === "file") ? e.target.files[0] : value

        this.handleFormChanges(name, value, type, passedOnChange);

    }

    handleRateChanges = (e, rateName, passedOnChange) => {
        this.handleFormChanges(rateName, e, "rate", passedOnChange);
    }

    handleRadioChanges = (e, name, passedOnChange) => {
        this.handleFormChanges(name, e, "radio", passedOnChange);
    }

    handleDateRangeChanges = (name, dateRange) => {
        this.handleFormChanges(name, dateRange, "dateRange");
    }

    onChangeReactDate = (name, date, onChange) => {
        const { formValues } = this.state
        formValues[name] = date
        this.setState({ formValues })
        onChange && onChange(date)
    }

    handleFormChanges = (name, selectedValue, type, passedOnChange) => {
        const { formValues } = this.state;
        const originalValue = selectedValue;

        if (isArray(selectedValue) && type !== "file") {
            selectedValue = map(selectedValue, 'value') || [];
            remove(selectedValue, n => !n);
        } else if (isObject(selectedValue) && !["file","dateRange"].includes(type)) {
            selectedValue = get(selectedValue, 'value')
        }

        formValues[name] = selectedValue;

        this.setState({ formValues });
        passedOnChange && passedOnChange(selectedValue, name, originalValue);
    }

    formField = (field) => {
        const { type = "text" } = field
        switch (type) {
            case 'text':
            case 'textarea':
            case 'date':
            case 'number':
            case 'readonly':
            case 'readonlytext':
            case 'checkbox':
            case 'link':
            case 'email': return this.buildInputField(field);
            case 'file': return this.buildFileField(field);
            case 'select': return this.buildCombobox(field);
            case 'reactDate': return this.buildReactDate(field);
            case 'dateRange': return this.buildDateRange(field);
            case 'reactMonth': return this.buildReactMonthSelector(field);
            case 'button': return this.buildButton(field);
            case 'radio': return this.buildAntdRadioButton(field);
            case 'rate': return this.buildAntdRateField(field);
            case 'inputgroup': return this.buildInputGroup(field);
            case 'hidden': return (<Fragment />)
            case 'default': return (<Fragment />);
            default: return (<Fragment />);
        }
    }

    buildDateRange = (field) => {
        const { name, label, required, error } = field;
        const { formValues } = this.state;
        const startDate = get(formValues, `${name}.startDate`, getDefaultStartDateFilter());
        const endDate = get(formValues, `${name}.endDate`, getDefaultEndDateFilter());

        return (
            <Fragment>
                <div>
                    {label && this.renderLabel(required, label)}
                </div>
                <DateRangePicker 
                    onChangeDateRange={(startDate, endDate) => { this.handleDateRangeChanges(name, {startDate, endDate}) }}
                    start={startDate} 
                    end={endDate}
                />
                {error && <div style={{ color: "red", fontSize: "13px" }}>{error}</div>}
            </Fragment>
        );
    }

    buildAntdRadioButton = (field) => {
        const { label, name, required, options, size = 'default', onChange } = field;

        let selectedOption = options.find(option => option.checked === true);

        return (
            <Fragment>
                {label && this.renderLabel(required, label)}<br />
                <RadioGroup size={size}
                    options={options}
                    value={selectedOption && selectedOption.value}
                    onChange={(e) => { this.handleRadioChanges(e, name, onChange) }}
                    {...field}
                />
            </Fragment>
        );
    }

    buildReactDate = (field) => {
        let { name, label, filter, required, minDate = "", onChange, error, disabled, removeValue } = field;
        const { formValues } = this.state
        let value = formValues[name];
        if (disabled && removeValue) {
            value = ""
        }

        const filterPassedTime = (time) => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() <= selectedDate.getTime();
        };

        return (
            <Fragment>
                <div>
                    {label && this.renderLabel(required, label)}
                </div>
                <DatePicker
                    {...field}
                    calendarIcon="Calendar"
                    name={name}
                    selected={value ? value : null}
                    onChange={(date) => this.onChangeReactDate(name, date, onChange)}
                    className="form-control"
                    filterDate={filter}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    minDate={minDate}
                    timeIntervals={1}
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
                {error && <div style={{ color: "red", fontSize: "13px" }}>{error}</div>}
            </Fragment>
        )
    }

    buildInputField = (field) => {
        let { type, name, removeValue, label, placeholder, value, required, error, options, inline, disabled, onChange, style } = field
        const { formValues } = this.state
        value = !isEmpty(formValues) ? formValues[name] : value
        if (disabled && removeValue) {
            value = ""
        }
        //If type is date, format the value to set value in datepicker
        if (type === "date") {
            value = value && moment(value).format('YYYY-MM-DD');
        }
        return <FieldGroup
            {...field}
            style={style}
            name={name}
            type={type}
            disabled={type === "readonly" ? true : disabled}
            label={label}
            placeholder={placeholder ? `${placeholder} ${required ? '*' : ''}` : ''}
            value={value || ""}
            options={options || null}
            inline={inline}
            valid={error ? "error" : null}
            errorMessage={error}
            required={required}
            onChange={(e) => this.handleFieldChanges(e, onChange)}
        />
    }

    buildInputGroup = (field) => {
        const { items, label, required, error } = field;
        return (
            <FormGroup>
                {label && this.renderLabel(required, label)}
                <Row noGutters className={`${error ? 'is-invalid' : ''}`}>
                    {
                        items.map(item =>
                            <>
                                <Col md={item.span}>
                                    <div style={{ width: "95%" }}>
                                        {this.formField(item)}
                                    </div>
                                </Col>{" "}
                            </>)
                    }
                </Row>
                {(error) && <FormFeedback>{error}</FormFeedback >}
            </FormGroup>
        )
    }

    buildFileField = (field) => {
        let { type, name, label, placeholder, value, required, accept, innerHtml, error, onChange } = field
        const { formValues } = this.state

        value = !isEmpty(formValues) ? formValues[name] : value
        return <FieldGroup
            {...field}
            name={name}
            type={type}
            label={label}
            placeholder={required && placeholder ? `${placeholder} *` : ''}
            innerHtml={innerHtml}
            files={value || ""}
            accept={accept}
            valid={error ? "error" : null}
            errorMessage={error}
            required={required}
            onChange={(e) => this.handleFieldChanges(e, onChange)}
        />
    }

    buildCombobox = (field) => {
        let { name, label, placeholder = "", value, required,
            options, model, displayKey, valueKey, isMulti, valueRenderer, filter, loadByDefault, error, onChange, help, inlineLabel } = field
        const { formValues } = this.state

        value = !isEmpty(formValues) ? formValues[name] : value
        const customStyles = {
            control: () => {
                return {
                    border: !error ? '#ddd' : '1px solid #dc3d3d'
                }
            }
        }

        let comp = <div />

        /**Local combobox */
        if (isArray(options)) {
            comp = <>
                <ClinicusComboBox
                    {...field}
                    label={label}
                    name={name}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), ...customStyles }}
                    value={value ? value : ""}
                    isMulti={isMulti}
                    valid={value || ""}
                    placeholder={required && placeholder ? `${placeholder} *` : `${placeholder}` }
                    options={options ? options : null}
                    onChange={(name, selectedValue) => this.handleFormChanges(name, selectedValue, null, onChange)}
                    valueRenderer={valueRenderer}
                    required={required}
                    inlineLabel={inlineLabel}
                />
                {help && <small className="form-text text-muted">{help}</small>}
            </>
        }
        /**Remote combobox */
        else {
            comp = <>
                <ClinicusComboBox
                    {...field}
                    label={label}
                    name={name}
                    styles={customStyles}
                    value={value ? value : ""}
                    isMulti={isMulti}
                    valid={value || ""}
                    placeholder={required && placeholder ? `${placeholder} *` : ''}
                    model={model}
                    filter={filter}
                    displayKey={displayKey}
                    valueKey={valueKey}
                    loadByDefault={loadByDefault}
                    onChange={(name, selectedValue) => this.handleFormChanges(name, selectedValue, null, onChange)}
                    valueRenderer={valueRenderer}
                    required={required}
                />

                {help && <small className="form-text text-muted">{help}</small>}
            </>

        }
        return (
            <Fragment>
                {comp}
                <>{error &&
                    <div style={{ 'color': '#f86c6b', fontSize: '80%' }}>
                        {error}
                    </div>}
                </>
            </Fragment>
        )
    }

    buildButton = (field) => {
        let { name, text, bsStyle, disabled, onClick, style, icon, label, required, error } = field;

        delete field.bsStyle;
        return (
            <FormGroup>
                {label && this.renderLabel(required, label)}
                <Row noGutters className={`${error ? 'is-invalid' : ''}`}>
                    <Button
                        {...field}
                        name={name}
                        color={bsStyle || "primary"}
                        disabled={disabled || false}
                        onClick={onClick ? onClick : null}
                        style={style}
                    >
                        {(icon && <i className={icon} />)} {text}
                    </Button>
                </Row>
            </FormGroup>
        );
    }

    renderLabel = (required, label) => {
        const star = (required) ? <span style={{ color: 'red' }}>*</span> : ''

        return (
            <Label className="text-xs font-semibold">
                {label} {star}
            </Label>
        )
    }

    getFormValues = () => {
        let formValues = this.filterFormValues();

        Object.keys(formValues).forEach((key) => {
            formValues[key] = (formValues[key] && formValues[key].trim) ? formValues[key].trim() : formValues[key]
        });
        return formValues
    }

    // setFormValues = (updatedVlues = {}) => {
    //     this.setState({ formValues: updatedVlues })
    // }

    emptyFormValues = () => {
        this.setState({ formValues: {} })
    }

    setFormValues = (newFormValues) => {
        /**
         * While setting formValues make sure merge the existing
         * formValues with the incoming formValues as well
        */
        const allFormValues = {
            ...this.state.formValues,
            ...newFormValues
        }
        this.setState({ formValues: allFormValues });
    }

    /**Validate Form Values */
    validateForm = () => {
        const { fields, formValues } = this.state;
        let validationErrors = {}
        fields.forEach((field) => {
            if (field.required && !formValues[field["name"]]) {
                validationErrors[field["name"]] = `${field["label"]} is required`
            }
        })

        document.scrollingElement.scrollIntoView();
        return validationErrors;
    }

    filterFormValues = () => {
        const { exactFormValues } = this.props;
        let { fields, formValues } = this.state;
        const fieldKeys = map(fields, 'name');

        if (exactFormValues) {
            formValues = pick(formValues, fieldKeys);
        }

        return formValues;
    }

    /**Validate Form  and get Values if valid */
    validateFormAndGetValues = () => {
        const { validateValues } = this.props;
        let { fields, formValues } = this.state;

        formValues = this.filterFormValues();
        let validationErrors = {}
        fields.forEach((field) => {
            if (field.type === 'inputgroup') {
                field.items.forEach(subField => {
                    if (subField.required && !formValues[subField["name"]]) {
                        validationErrors[subField["name"]] = `${subField["label"] || startCase(subField["name"])} is required`
                    }
                })
            } if (field.required && !formValues[field["name"]]) {
                validationErrors[field["name"]] = `${field["label"] || startCase(field["name"])} is required`
            }
        });

        if (isEmpty(validationErrors)) {
            /**If extra validations are to be performed, pass validateValues as  a function for which input param will be formValues */
            const additionalValidationErrors = validateValues ? validateValues(formValues) : {};
            this.setState({ validationErrors: additionalValidationErrors });
            if (!isEmpty(additionalValidationErrors)) {
                document.scrollingElement.scrollIntoView();
                // this.setState({ validationErrors: additionalValidationErrors });
                return null;
            }
            return this.getFormValues();
        }
        else {
            document.scrollingElement.scrollIntoView();
            this.setState({ validationErrors });
            return null;
        }
    }






    
    // DONE
    componentWillReceiveProps = (nextProps, nextState) => {
        const { formValues } = this.state;

        /**Update the form validationsErrors with the incoming validationErrors */
        if (!isEqual(this.props.validationErrors, nextProps.validationErrors)) {
            this.setState({ validationErrors: nextProps.validationErrors })
        }

        /**Update the formvalues with the initialValues passed */
        if (!isEmpty(nextProps.initialValues) && nextProps.initialValues && !isEqual(nextProps.initialValues, this.props.initialValues)) {
            /**If formValues are empty, take incoming initialValues else merge formvalues and initialValues */
            isEmpty(this.state.formValues) ?
                this.setState({ formValues: nextProps.initialValues }) :
                this.setState({ formValues: { ...formValues, ...nextProps.initialValues } });
        }

        /**Update the form fields dynamically */
        if (!isEqual(nextProps.fields, this.state.fields)) {
            this.setState({ fields: nextProps.fields });
        }
    }

    // DONE
    componentDidMount = () => {
        const { initialValues, validationErrors, fields } = this.props
        this.setState({
            fields,
            formValues: !isEmpty(initialValues) ? { ...initialValues } : {},
            validationErrors
        })
    }

    // DONE
    render = () => {
        let { fields = [] } = this.state;
        const { cols, rows, heading } = this.props
        const { validationErrors } = this.state
        const rowDivision = rows || cols;
        remove(fields, (n) => !n);
        const fieldRows = chunk(fields, rowDivision)
        let md = Math.ceil(12 / cols)

        const fieldDOM = fieldRows.map((fieldRow, index) => {
            return <Fragment key={index}>
                <Row >
                    {fieldRow.map((field, index) => {
                        const { name } = field
                        field["error"] = validationErrors ? validationErrors[name] : null
                        return <Col key={index} md={field.md || md} className="form-builder-field">
                            {this.formField(field)}
                        </Col>
                    })}
                </Row>
                {fieldRows.length != index+1 ? <ClinicusDivider /> : null}
                <div className="empty-row" />
            </Fragment>
        })

        return (
            <>
                {heading &&
                    <>
                        <h5 className="form-heading">{heading}</h5>
                        {/* <Divider ></Divider> */}
                    </>
                }
                <div className="form-builder">
                    {fieldDOM}
                </div>
            </>
        )
    }
}

export default FormBuilder;