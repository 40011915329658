// import React, { useState } from 'react';
// import { ChevronDownIcon } from '@heroicons/react/24/outline';
// import { Dropdown } from 'antd';

// import { dropdownConfigs, textConfigs } from 'configs/appConfigs';

// const ClinicusDropdown = ({ dropdownClassName = "", displayComponentClassName = "", options = [], onClickOption, displayText = null }) => {
//     const [isOpen, setIsOpen] = useState(false);

//     return (
// //         <div class="relative inline-block text-left">
// //   <div onClick={() => setIsOpen(!isOpen)}>
// //     <button type="button" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
// //       Options
// //       <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
// //         <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
// //       </svg>
// //     </button>
// //   </div>
// // {/* 
// //   <!--
// //     Dropdown menu, show/hide based on menu state.

// //     Entering: "transition ease-out duration-100"
// //       From: "transform opacity-0 scale-95"
// //       To: "transform opacity-100 scale-100"
// //     Leaving: "transition ease-in duration-75"
// //       From: "transform opacity-100 scale-100"
// //       To: "transform opacity-0 scale-95"
// //   --> */}
// //   <div class={"absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " + (isOpen ? "" : " hidden")} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
// //     <div class="py-1" role="none">
// //       {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
// //       <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-0">Edit</a>
// //       <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-1">Duplicate</a>
// //     </div>
// //     <div class="py-1" role="none">
// //       <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-2">Archive</a>
// //       <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-3">Move</a>
// //     </div>
// //     <div class="py-1" role="none">
// //       <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-4">Share</a>
// //       <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-5">Add to favorites</a>
// //     </div>
// //     <div class="py-1" role="none">
// //       <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-6">Delete</a>
// //     </div>
// //   </div>
// // </div>

//     // );

//     // const onClickMenu = ({ key }) => {
//     //     const option = options.find(op => op.value == key);

//     //     onClickOption && onClickOption(option);
//     // }

//     // return (
//     //     <div className={`${dropdownClassName} p-2 cursor-pointer ${displayComponentClassName ? displayComponentClassName : dropdownConfigs.colors.defaults.bgClassName + " rounded-md"} ${textConfigs.colors.defaults.textColorClassName} font-semibold border-[#CCD7E4] border-[1px] border-solid flex justify-between items-center`}>
//     //         <Dropdown  menu={{ items: options.map(op => ({ ...op, key: op.value })), onClick: onClickMenu }} placement="bottom" arrow trigger={["click"]}>
//     //             <div className={`flex justify-between items-center w-full`}>
//     //                 <span className="whitespace-nowrap overflow-hidden text-ellipsis">{displayText?.label || "No option selected"}</span> <ChevronDownIcon className="w-5 h-5" />
//     //             </div>
//     //         </Dropdown>
//     //     </div>
//     // );
// }

import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';

import { dropdownConfigs, textConfigs } from 'configs/appConfigs';

const ClinicusDropdown = ({ isOpen, toggle, dropdownClassName = "", displayComponentClassName = "", options = [], onClickOption, displayText = null, size = "md", hideDropdownIcon = false }) => {
    return (
        // <Dropdown isOpen={isOpen} toggle={toggle} className={`${dropdownClassName}`}>
        //     <DropdownToggle size={size} className={`${displayComponentClassName ? displayComponentClassName : dropdownConfigs.colors.defaults.bgClassName} ${textConfigs.colors.defaults.textColorClassName} font-semibold border-[#CCD7E4] border-[1px] border-solid flex justify-between items-center w-full`}>
        //         <span className="whitespace-nowrap overflow-hidden text-ellipsis">{displayText?.label || "No option selected"}</span> <ChevronDownIcon className="w-5 h-5" />
        //     </DropdownToggle>
        //     <DropdownMenu>
        //         {options.map(option => (
        //             <DropdownItem className="hover:bg-[#c6cfff]" key={option.value} onClick={() => onClickOption(option)}>
        //                 {option.label}
        //             </DropdownItem>
        //         ))}
        //     </DropdownMenu>
        // </Dropdown>
        <UncontrolledDropdown className={`${dropdownClassName}`}>
            <DropdownToggle size={size} className={`${displayComponentClassName ? displayComponentClassName : dropdownConfigs.colors.defaults.bgClassName} ${textConfigs.colors.defaults.textColorClassName} font-semibold border-[#CCD7E4] border-[1px] border-solid flex justify-between items-center w-full`}>
                <span className="whitespace-nowrap overflow-hidden text-ellipsis">{displayText?.label || "No option selected"}</span> {hideDropdownIcon ? "" : <ChevronDownIcon className="w-5 h-5" />}
            </DropdownToggle>
            <DropdownMenu>
                {options.map(option => (
                    <DropdownItem className="hover:bg-[#CCD7E4] w-full" key={option.value} onClick={() => onClickOption(option)}>
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default ClinicusDropdown;
