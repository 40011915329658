import React, { useState, useEffect, useRef } from 'react';
import { isEqual, map } from 'lodash';
import { Spin } from 'antd';

import ClinicusDivider from 'common/ClinicusDivider';
import CheckboxV2 from 'common/Form/ScioCheckbox';

const ScioComboBox = ({ field, value, onChange, error, onChangeSearchTerm, loading = false, isSingleSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(value || []);
    const [filteredOptions, setFilteredOptions] = useState(field.options || []);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if(!isEqual(value, selectedOptions))
            setSelectedOptions(value || []);

        if(!isEqual(field.options, filteredOptions))
            setFilteredOptions(field.options || []);
    }, [value, field.options]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                setSearchTerm('');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(!isEqual(value, selectedOptions)) {
            onChange(field.name, selectedOptions);
        }
    }, [selectedOptions]);

    useEffect(() => {
        if(!isEqual(field.options, filteredOptions)) {
            const filtered = field.options.filter(option => 
                option?.label?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
            setFilteredOptions(filtered);
        }
        onChangeSearchTerm && onChangeSearchTerm(searchTerm);
    }, [searchTerm]);

    const toggleOption = (option) => {
        const selectedOps = map(selectedOptions, "value");

        if(isSingleSelect === true) {
            if(selectedOps.includes(option.value)) {
                setSelectedOptions([]);
            } else {
                setSelectedOptions([option]);
            }
            return;
        }

        if(selectedOps.includes(option.value)) {
            setSelectedOptions(selectedOps.filter(item => item.value !== option.value));
        } else {
            setSelectedOptions([ ...selectedOps, option ]);
        }
    }

    const removeOption = (option, event) => {
        event.stopPropagation();
        setSelectedOptions(prev => prev.filter(item => item !== option));
        setIsOpen(true);
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchTerm && !filteredOptions.some(option => option.label.toLowerCase() === searchTerm.toLowerCase())) {
            const newOption = { value: searchTerm, label: searchTerm };
            setFilteredOptions(prev => [...prev, newOption]);
            toggleOption(searchTerm);
            setSearchTerm('');
        }
    }

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div
                className={`p-2 border rounded cursor-pointer ${error ? 'border-red-500' : isOpen ? 'border-[#0463D1]' : 'border-gray-300'}`}
                onClick={() => {
                    setIsOpen(!isOpen);
                    if (!isOpen) {
                        setTimeout(() => inputRef.current.focus(), 0);
                    }
                }}
            >
                {selectedOptions.length === 0 ? (
                    <span className="text-gray-500">Select options</span>
                ) : (
                    <div className="flex flex-wrap gap-1">
                        {selectedOptions.map(option => (
                            <div key={option.value} className="bg-[#E4F2FF] px-2 py-1 rounded text-sm flex gap-2 items-center">
                                <span className="font-semibold">{field.options.find(o => o.value === option.value)?.label || option.label}</span>
                                <i className="clinicus-close focus:outline-none cursor-pointer text-blue-500" onClick={(e) => removeOption(option, e)} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
                    <div className="p-2">
                        <input
                            ref={inputRef}
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            className="w-full p-1 border rounded focus:border-[#0463D1]"
                            placeholder="Please start typing..."
                        />
                    </div>
                    <div className="min-h-14">
                        <Spin spinning={loading} tip="Loading options...">
                            {filteredOptions && filteredOptions.length ? <div className="max-h-48">
                                {filteredOptions.map((option, idx) => (
                                    <div key={idx}>
                                        <div
                                            className="p-2 hover:bg-gray-100 cursor-pointer flex items-center gap-3"
                                            onClick={() => toggleOption(option)}
                                        >
                                            <CheckboxV2 onChange={() => {}} checked={map(selectedOptions, "value").includes(option.value)} />
                                            <span className="font-semibold">{option.label}</span>
                                        </div>
                                        {filteredOptions.length-1 !== idx ? <ClinicusDivider className="my-0" /> : null}
                                    </div>
                                ))}
                            </div> : <div className="grid place-content-center p-2 font-semibold">No options present!</div> }
                        </Spin>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ScioComboBox;