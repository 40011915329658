import React, { useState } from 'react';
import { Spin } from 'antd';

import FormBuilder from '../Form/FormBuilder';
import ModalWindow from '../ModalWindow/ModalWindow';

const AddModalView = ({ formFields, isOpen, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (value, name) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        if(onSubmit) {
            await onSubmit(formData);
        }
        setFormData({});
        setLoading(false);
        onClose && onClose();
    };

    const appliedFormFields = formFields.map(field => {
        if(field?.onChange) {
            field.onChange = handleChange;
        }

        return field;
    });

    return (
        <ModalWindow fullscreen title="Add new row" onClickConfirm={() => handleSubmit()} isOpen={isOpen} toggleModal={() => onClose()} confirmBtnText={'Save'}>
            <Spin spinning={loading} tip="Processing request...">
                <FormBuilder
                    cols={3}
                    fields={appliedFormFields}
                />
            </Spin>
        </ModalWindow>
    );
};

export default AddModalView;
