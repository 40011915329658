import React from 'react';
import PropTypes from 'prop-types';

import Error from './Error';

const ErrorWrapper = ({ children, error, position = 'top' }) => {
    const isPosTop = position === "top";

    return (
        <div className="p-[2px]">
            {isPosTop && <Error error={error} />}
            {children}
            {!isPosTop && <Error error={error} />}
        </div>
    );
}

ErrorWrapper.propTypes = {
    children: PropTypes.node,
    error: PropTypes.string,
    position: PropTypes.oneOf(["top", "bottom"]),
}

export default ErrorWrapper;
