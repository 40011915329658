import { HomeIcon, UserGroupIcon } from "@heroicons/react/24/outline";

import { permissions } from "./permissionsList";

export const navItemsIconMapping = {
    'homepage': <HomeIcon className="h-5 w-5" />,
    'patients': <UserGroupIcon className="h-5 w-5" />
};

export const navItems = [{
    appName: "Homepage",
    configName: "homepage",
    pathName: "/homepage",
    permissions: [...Object.values(permissions.homepage)]
}];

export const KEYS = {
    ACCESS_TOKEN: "accessToken"
};
