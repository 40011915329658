import React from "react";
import PropTypes from "prop-types";

const LoadingWrapper = ({ loading, children, ...props }) => (
    <div className="relative" {...props}>
        {children}
        {loading && 
            (<div className="absolute inset-0 flex items-center justify-center bg-[#f0f3f5] bg-opacity-70 z-50">
                <i className="fa fa-2x fa-refresh fa-spin"></i>
            </div>)
        }
    </div>
);

LoadingWrapper.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node
};

export default LoadingWrapper;
