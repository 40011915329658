import React, { useEffect, useState } from 'react';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { Toaster, toast } from 'react-hot-toast';
import { get } from 'lodash';
import { Spin } from 'antd';

import { getAccessToken } from './msalAuth';
import { clearStoreData, setAppDataInLocalStorage, setUserData } from './utils/appUtils';

import { axiosGet } from './utils/axiosAsync/async';
import { toastConfigs } from 'configs/appConfigs';

import AuthorisedRoutes from './AuthorisedRoutes';
import UnauthorisedRoutes from './UnauthorisedRoutes';
// import AmazonConnect from './amazon-connect/amazon-connect';

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const MainContent = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("loggedOut");
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const activeEmail = get(activeAccount, 'username', '');

    useEffect(() => {
        if(activeAccount) {
            authoriseUser();
        } else {
            setLoading("loggedOut");
            setAppDataInLocalStorage("");
            clearStoreData();
        }
    },[activeEmail]);

    const authoriseUser = async () => {
        setLoading("loggingIn");
        const [err, resp] = await getAccessToken();
        const [userErr, userResp] = await setUserData(activeEmail, false, "");

        if(err) {
            setLoading("loggingError");
            setError(err);
            setAppDataInLocalStorage("");
            clearStoreData();
            toast.error(err);
            return;
        }

        if(userErr) {
            setLoading("loggingError");
            setError(userErr);
            setAppDataInLocalStorage("");
            clearStoreData();
            toast.error(err);
            return;
        }

        setAppDataInLocalStorage(resp.accessToken);
        setError("");
        setLoading("loggedIn");
    }

    return (
        <div className="App">
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <Spin spinning={loading === "loggingIn"} tip="Loading your details..." className="h-screen">
                        {loading === "loggingIn" ? <div>Loading...</div> : null}
                        {loading === "loggedIn" ? <AuthorisedRoutes /> : null}
                        {loading === "loggingError" ? <div>Error loading your details...</div> : null}
                    </Spin>
                ) : `You're not logged in!`}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UnauthorisedRoutes />
            </UnauthenticatedTemplate>
        </div>
    );
}

const App = ({ instance }) => {
	return (
        <MsalProvider instance={instance}>
            <React.Suspense fallback={<div>Loading please wait...</div>}>
                <MainContent />
                <Toaster toastOptions={toastConfigs.defaults} />
            </React.Suspense>
        </MsalProvider>
        // <div>
        //     <AmazonConnect/>
        // </div>
	);
}

export default App;
