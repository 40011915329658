import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Title from '../Title';
import Button from 'common/Button/Button';

const ModalWindow = ({ title, titleIcon = null, isOpen = false, toggleModal, fullscreen = false, children, disableConfirmBtn = false,
    customFooter, confirmBtnText = "Confirm", confirmBtnType = "primary", cancelBtnText = "Cancel", onClickConfirm = () => {},
    onClickCancel, headerClasses = "", bodyClasses = "", footerClasses = "", noFooter = false, fullWidth = false, size = "md" }) => {

    return (
        <div>
            <Modal size={size} fade={true} isOpen={isOpen} toggle={toggleModal} fullscreen={fullscreen} className={`${fullscreen ? "w-full" : ""} ${fullWidth ? "max-w-full md:mx-36" : ""}`} backdropClassName="custom-backdrop">
                {title ? <ModalHeader className={headerClasses} toggle={toggleModal}>
                    <Title titleElement={title} iconElement={titleIcon} />
                </ModalHeader> : null}
                <ModalBody className={bodyClasses}>
                    {children}
                </ModalBody>
                {!noFooter && <ModalFooter className={footerClasses}>
                    {customFooter ? customFooter :
                        <div>
                            <Button type="secondary" onClick={onClickCancel ? onClickCancel : toggleModal}>
                                {cancelBtnText}
                            </Button>{' '}
                            <Button disabled={disableConfirmBtn} type={confirmBtnType || "primary"} onClick={() => onClickConfirm()}>
                                {confirmBtnText}
                            </Button>
                        </div>
                    }
                </ModalFooter>}
            </Modal>
        </div>
    );
}

export default ModalWindow;
