import React from 'react';
import { Divider } from 'antd';

import { dividerConfigs } from '../configs/appConfigs';

const ClinicusDivider = ({ className = "", type = "horizontal" }) => {
    return (
        <Divider type={type} className={`${dividerConfigs.colors.defaults.borderColor} border-t ${className}`} />
    );
}

export default ClinicusDivider;
