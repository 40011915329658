import React, { useState , useEffect, useRef} from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { isEqual, get, concat, isEmpty, isArray, isObject, remove, uniqWith } from 'lodash';

import { axiosPost } from '../../utils/axiosAsync/async';

const ClinicusComboBox = (props) => {
    const [loading , setLoading] = useState(false);
    const [options , setOptions] = useState([]);
    const [inputValue , setInputValue] = useState("");
    const [selectedValue , setSelectedValue] = useState(null);
    const [searchableValues,setSearchableValue] = useState(undefined);

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    
    const prevState = usePrevious({ loading, options, inputValue, selectedValue, searchableValues}) || {};
    const prevProps = usePrevious({...props}) || {};

    useEffect(() => {
        const { loadByDefault, isMulti, value, displayKey } = props;
        /**For multi select - if initial value is passed, take all the values 
         * in array and set it as inputValue so that it will get set properly */
        if (!isEmpty(value)) {
            const searchables= isMulti ? value.map(val => isObject(val) ? get(val, displayKey) : val) : [value];
            setSearchableValue(searchables);
            getComboValues();
        } else if (loadByDefault !== false) { /**Load the combobox, unless loadByDefault is specified as false*/
            getComboValues();
        }
    },[]);

    useEffect(() => {
        if (!isEqual(prevProps.filter, props.filter)) {
            getComboValues()
        }
        if (!isEqual(prevState.inputValue, inputValue)) {
            getComboValues()
        }

        if (!isEqual(prevProps.options, props.options)) {
            processComboResponse(props.options);
        }
    },[props.filter, inputValue,props.options]);

    useEffect(() => {
        if (!isEmpty(props.options) && !isEqual(props.options, options)) {
            setOptions(props.options);
        }
    },[props.options]);

    const getComboValues = async () => {
        const { options, apiConfig } = props;

        /*Combobox with Local values*/
        if (options) {
            processComboResponse(options);
        }
        /*Combobox with remote values*/
        else {
            if (apiConfig) {
                makeCallWithoutModel();
            }
            else {
                makeCallWithModel();
            }
        }
    }

    const makeCallWithoutModel = async () => {
        const { displayKey, valueKey, apiConfig } = props;
        const { url, method: apiMethod, body, isNucleusApi } = apiConfig;

        const apiUrl = url;
        const apiBody = apiMethod == "GET" ? null : body;
        try {
            setLoading(true);
            const queryOptions = {
                method: apiMethod,
                headers: this.generateRequestHeaders(),
                body: apiBody ? formatRequestBody(apiBody) : null,
                credentials: "same-origin"
            };

            setLoading(true);
            await fetch(apiUrl, queryOptions).then(res =>
                res.json()
                    .then(response => {
                        // if (!response.ok)
                        //     return Promise.reject({ ...json, status: response.status });
                        processComboResponse(response);
                    })
                    .catch(error => {
                        return error;
                    })
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    const makeCallWithModel = async () => {
        let { displayKey, valueKey, model, filter = {}, isMulti, db } = props;

        /**dispalyKey and valueKeyFilter should go as or condition */
        let extraFilter = [
            inputValue ? {
                [displayKey]: { $regex: inputValue, $options: "i" }
            } : null,
            (searchableValues) ? {
                [valueKey]: (isArray(searchableValues)) ? { $in: searchableValues } : searchableValues
            } : null];

        remove(extraFilter, f => isEmpty(f));
        const updatedFilter = !isEmpty(extraFilter) ? {
            "$or": extraFilter
        } : {};
        // if (inputValue) {
        //     filter[displayKey] = { $regex: inputValue, $options: "i" }
        // }
        // else if (isMulti) {
        //     filter[valueKey] = (isArray(searchableValues)) ? { $in: searchableValues } : searchableValues
        // }

        filter = Object.assign(filter, updatedFilter)

        const bodyPayload = {
            model, db, filter, displayKey, valueKey
        }
        const apiUrl = `/combo`;
        const apiBody = bodyPayload;
        try {
            setLoading(true);
            const [error, response] = await axiosPost(apiUrl, apiBody)
            processComboResponse(response.data);
        } catch (error) {
            throw new Error(error);
        }
    }

    const processComboResponse = (response) => {
        const { displayKey, valueKey, isMulti } = props;

        if (Array.isArray(response)) {
            let updatedOptions = response.map((res) => {
                return {
                    label: isObject(res) ? get(res, displayKey || 'label') : res,
                    value: isObject(res) ? get(res, valueKey || 'value') : res
                }
            });

            /**If multi select, append the serchable values to the existing list, else the initial values will be removed. */
            if (isMulti) {
                updatedOptions = uniqWith(concat(updatedOptions, options), isEqual);

            }

            setLoading(false);
            setOptions(updatedOptions);
        }

        return options;
    }

    const formatRequestBody = (body) => {
        return JSON.stringify(body);
    }

    const onInputChangeHandler = (newValue) => {
        newValue = newValue && newValue.trim();
        if (newValue) {
            setInputValue(newValue);
        }
    }

    const getFormattedInitialValue = (value) => {
        const { displayKey, valueKey } = props;

        if (value) {
            if (isArray(value)) {
                const formattedValues = value.map((val) => {
                    return (!isObject(val)) ? val : val[valueKey]
                });

                return formattedValues;
            }
            else if (isObject(value)) {
                return value[valueKey];
            }
            else {
                return value;
            }
        }
    }

    const renderLabel = (required, label) => {
        const star = (required) ? <span style={{ color: 'red' }}>*</span> : ''

        return (
            <Label>
                {label} {star}
            </Label>
        )
    }

    /**@stateKey : state value which needs to be updated*/
    const { label, name, value, placeholder, defaultValue,
        onChange, onInputChange, required, isMulti, stateKey, disabled, loading: passedLoading,
        valueRenderer, customStyles, className, onBlur,
        displayKey, valueKey, filterOptions, inlineLabel, clearable } = props;
        
    /**If filterOption function is passed, filter the options  */
    const formatOptionsArray = filterOptions ? filterOptions(options) || [] : options;

    const labelStyle = inlineLabel ? { display: 'flex' } : {};

    return (
        <div style={labelStyle}>
            {label && renderLabel(required, label)}
            <Select
                // style={customStyles}
                clearable={clearable || false}
                // className={"cursor-pointer"}
                options={formatOptionsArray}
                label={label}
                name={name}
                value={isEmpty(value) ? "" : value}
                isLoading={loading || passedLoading}
                placeholder={placeholder || 'Please start typing'}
                defaultValue={defaultValue}
                multi={isMulti ? true : false}
                isSearchable={true}
                onInputChange={onInputChange || onInputChangeHandler}
                valueRenderer={valueRenderer ? valueRenderer : null}
                onChange={onChange && ((selectedOption) => {
                    onChange(name, selectedOption, isMulti, stateKey)
                })}
                disabled={disabled || false}
                onBlur={onBlur}
            />
        </div>
    )
}

export default ClinicusComboBox;
