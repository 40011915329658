import React, { useEffect, useState } from 'react';
import { get, remove, isEmpty } from 'lodash';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

const TabBuilder = (props) => {
    const { defaultTab, tabs = [], onSelect } = props;
    const [selectedTab, setSelectedTab] = useState(1);

    useEffect(() => {
        const tab = defaultTab ? defaultTab : selectedTab;
        handleSelect(tab);
    },[]);

    const handleSelect = (eventkey) => {
        setSelectedTab(eventkey);
        onSelect && onSelect(eventkey, tabs);
    }

    //Removing hidden tabs
    remove(tabs, t => get(t, 'hidden') || isEmpty(t));

    return (
        <>
            <Nav tabs>
                {tabs.map((tab, idx) => (
                    <NavItem key={idx}>
                        <NavLink
                            active={selectedTab === idx + 1}
                            onClick={() => { handleSelect(idx + 1); }}
                            disabled={get(tab, 'disabled', false)}
                            className="cursor-pointer"
                        >
                            {tab.icon && <i className={tab.icon}></i>}
                            {' '}
                            {tab.title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={selectedTab} id="react-tabs">
                {tabs.map((tab, idx) => (
                    (selectedTab === idx + 1) && <TabPane tabId={idx + 1} key={idx}>
                        {tab.component}
                    </TabPane>
                ))}
            </TabContent>
        </>
    );
}

export default TabBuilder;
