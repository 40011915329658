import { get } from 'lodash';

import { updateUserInfo } from '../store/userSlice';
import { updateNavItems } from '../store/navSlice';
import { setInLocalStorage } from './localstorage';
import { KEYS, navItems } from '../constants';
import { validatePermissions } from '../permissionsList';
import store from '../store/store';
import { axiosGet } from './axiosAsync/async';

const getNavItems = () => {
    return navItems;
}

export const setAppDataInLocalStorage = (accessToken) => {
    setInLocalStorage(KEYS.ACCESS_TOKEN, accessToken);
}

export const setUserData = async (email, isImpersonatedUser = false, actualUserEmail) => {
    const [userErr, userResp] = await axiosGet("user/getDetails", { email, isImpersonatedUser, actualUserEmail });

    if(userErr) {
        setAppDataInLocalStorage("");
        clearStoreData();
    } else {
        setStoreData(userResp.data);
    }

    return [userErr, userResp];
}

export const unmaskImpersonateUtil = (user) => {
    const actualUser = user.actualUser || {};

    setStoreData(actualUser);
}

export const setStoreData = (userDetails) => {
    const navItems = getNavItems();
    const userPermissions = get(userDetails, 'permissions', []);
    const filteredNavItems = navItems.filter(item => validatePermissions(userPermissions, item.permissions));

    store.dispatch(updateUserInfo(userDetails));
    store.dispatch(updateNavItems({ navItems: filteredNavItems, selectedItem: 0, isSidebarOpen: window.innerWidth > 650 }));
}

export const clearStoreData = () => {
    store.dispatch(updateUserInfo({}));
    store.dispatch(updateNavItems({ navItems: [], selectedItem: -1, isSidebarOpen: false }));
}
