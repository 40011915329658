import moment from "moment-timezone";

export const getFormattedDate = (date, isStartDate) => {
    if (isStartDate === 'startdate') {
        return moment(date).add(5, "hours").add(30, "minutes").format("DD/MM/YYYY");
    } else {
        return moment(date).format("DD/MM/YYYY");
    }
}

export const getDefaultStartDateFilter = () => {
    return getStartDateOfWeeklyCycle(-13, "days").add(5, "hours").add(30, "minutes");
}

export const getDefaultEndDateFilter = () => {
    return moment().endOf("day");
}

export const getDefaultStartDateFilterForBDAs = () => {
    return getStartDateOfWeeklyCycle(-6, "months");
}

export const getDefaultDateFilterForPerformanceBDAs = () => {
    return getStartDateOfWeeklyCycle(-1, "months").add(5, "hours").add(30, "minutes");
}

export const getDefaultStartDatePerformanceFilter = () => {
    return getStartDateOfWeeklyCycle(-6, "days").add(5, "hours").add(30, "minutes");
}

export const getFormattedDateTime = (dateTime, format = "DD/MM/YYYY") => {
    if(!dateTime)   return "-";

    const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDt = moment(dateTime).tz(systemTz).format(format);

    return formattedDt;
}

const getStartDateOfWeeklyCycle = (startDate, timeSpan) => {
    let pastDate = moment().startOf("day").add(startDate, timeSpan);
    let day = moment(pastDate).weekday();

    if (day < 4) {
        let currDate = day + 3;
        pastDate = moment(pastDate).startOf("day").add(-currDate, "days");
    } else {
        let currDate = day - 4;
        pastDate = moment(pastDate).startOf("day").add(-currDate, "days");
    }
    return pastDate;
}
