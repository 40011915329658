import React from 'react';
import Proptypes from 'prop-types';
import { Alert } from 'reactstrap';

const Error = ({ error }) => {
    if (!error) return null;

    document.scrollingElement.scrollIntoView();

    return (
        <div className="mb-1">
            <Alert className="h-3 items-center bg-red-800 font-medium text-white grid">
                <div className="overflow-auto">
                    <i className="icon fa fa-exclamation-triangle mr-2" />
                    {error}
                </div>
            </Alert>
        </div>
    );
}

Error.propTypes = {
    error: Proptypes.string
}

export default Error;
