import React from 'react';
import { Button } from 'reactstrap';

import { buttonConfigs } from '../../configs/appConfigs';

const ClinicusButton = ({ children, className, type = "primary", disabled = false, ...props }) => {
    const btnType = disabled ? "disabled" : type;
    const btnClassNames = buttonConfigs.colors.defaults[btnType];

    return (
        <Button {...props} disabled={disabled} className={`${btnClassNames} font-semibold rounded-lg text-sm ${className}`}>
            {children}
        </Button>
    );
}

export default ClinicusButton;
