import axios from "axios";
import { axiosRequestInterceptor } from "../../interceptors";

const clinicusUrlMap = {
    "localhost:4200": "http://localhost:3006/api/clinicus",
    "dev.clinicus.ai": "https://clinicus-backend-35745f5628c1.herokuapp.com/api/clinicus",
    "clinicus.ai": "https://clinicus-backend-35745f5628c1.herokuapp.com/api/clinicus",
    "test.clinicus.ai": "https://test.clinicus.ai/api/clinicus",
    "clinicus-client.vercel.app": "https://clinicus-backend-35745f5628c1.herokuapp.com/api/clinicus",
    "clinicus-app-9a16309ddaa0.herokuapp.com": "https://clinicus-backend-35745f5628c1.herokuapp.com/api/clinicus", //pvt shield FE link
    "clinicus-client-bd96f90b51dc.herokuapp.com": "https://clinicus-backend-35745f5628c1.herokuapp.com/api/clinicus" //normal FE Link
};

let axiosInstance = null;

const getAxiosInstance = () => {
    if(!axiosInstance) {
        const hostName = window.location.host;
        const baseURL = clinicusUrlMap[hostName];

        axiosInstance = axios.create({ baseURL });
        axiosRequestInterceptor(axiosInstance);

        axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
    }

    return axiosInstance;
};

export default getAxiosInstance;
