import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { gridConfigs } from "configs/appConfigs";

import ClinicusGrid from "common/ClinicusGrid/ClinicusGridV2";
import BackButton from "common/BackButton/BackButton";

const SearchResults = () => {
  const gridRef = useRef(null);
  const location = useLocation();

  // Extract query parameters from URL
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("search_query") || "";

  const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatters = {
    linkFormatter: (cell, row) => {
      const { sfid } = row;
      return (
        <Link
          to={`/homepage/total-patients-report/details/vitals/${sfid}`}
          className={`underline ${gridConfigs.colors.defaults.linkFormatterClassName}`}
        >
          {cell}
        </Link>
      );
    },
    dateFormatter: (cell, row) => {
      if (cell) {
        return moment(cell).tz(systemTz).format("Do MMM, YY hh:mm A");
      }
      return "-";
    },
    dobFormatter: (cell, row) => {
      return moment(cell).tz(systemTz).format("MM/DD/YYYY");
    },
  };

  return (
    <div>
      <header>
        <BackButton
          path="/homepage/total-patients-report"
          text="All Patients"
          className="mx-3 mt-3"
        />
        <h1>Search Results for "{query}"</h1>
      </header>
      <ClinicusGrid
        gridDataUrl={`/object/search?query=${encodeURIComponent(query)}`}
        formatters={formatters}
        ref={gridRef}
        sort={{ createddate: "desc" }}
        objectId={"patients"}
      />
    </div>
  );
};

export default SearchResults;
